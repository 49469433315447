import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

// const container = document.getElementById('root')
// const root = createRoot(container)

// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
// )

ReactDOM.render(<><Provider store={store}><App /></Provider></>, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
